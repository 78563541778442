<template>
  <div>
    <div  class="top-post">
      <div class="home-filter" ref="homeFilter" @wheel="handleWheel">
        <div
            class="checkbox-custom relative"
            v-for="(item,i) in addTypes"
            :key="i"
        >
          <input type="checkbox"
                 class="check-box-filter checkbox-custom" name="AdsFilter"
                 @change="onChange"
                 :checked="value.includes(item.name)"
                 :value="item.name">
          <label  class="check-box-title">{{ item.translation }}</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "../../plugins/axios";

export default {
  name: "topFilter",
  data(){
    return{
      addTypes : null,
      topFilter : []
    }
  },
  computed :{

  },

  props: {
    checked: Boolean,
    value :{
      type : Array
    }
  },
  methods :{
    handleWheel(event) {
      if (event.deltaY < 0) {
        this.$refs.homeFilter.scrollLeft -= 50;
      } else if(event.deltaY > 0) {
        this.$refs.homeFilter.scrollLeft += 50;
      }
    },

    onChange(e){

      let currentValue = [...this.value]
      if (e.target.checked){
        currentValue.push(e.target.value);
      } else{
        currentValue = currentValue.filter(item => item !== e.target.value)
      }
      this.$store.commit('filters/CLEAR_ESTATES')
      this.$store.commit('filters/SET_FILTERS_ADDVER', currentValue);
      this.$store.dispatch('filters/getDataWithFilter')
      this.$emit('input', currentValue);
    },

  },
  mounted() {
    axios.get('options/ad-types')
        .then(({data})=>{
          this.addTypes = data.data
        })
  }
}
</script>

<style scoped>

</style>