<template>
  <transition name="fade" mode="out-in">
    <div class="filters h-full">
      <div class="filters-header">
        <span class="bongah-filter">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="option0s" transform="translate(20 0) rotate(90)">
    <rect id="Rectangle_524" data-name="Rectangle 524" width="20" height="20" transform="translate(0 0)" fill="#fff" opacity="0"/>
    <path id="Path_1287" data-name="Path 1287" d="M3.222,9.869V.81A.81.81,0,1,0,1.6.81V9.869a2.431,2.431,0,0,0,0,4.57V15.4a.81.81,0,1,0,1.621,0V14.44a2.431,2.431,0,0,0,0-4.57Z" transform="translate(2.848 1.958)" fill="#fff"/>
    <path id="Path_1288" data-name="Path 1288" d="M4.843,8.913A2.431,2.431,0,0,0,3.222,6.628V.81A.81.81,0,0,0,1.6.81V6.628a2.431,2.431,0,0,0,0,4.57v4.2a.81.81,0,1,0,1.621,0V11.2A2.431,2.431,0,0,0,4.843,8.913Z" transform="translate(12.571 1.958)" fill="#fff"/>
    <path id="Path_1289" data-name="Path 1289" d="M4.862,2.438A2.431,2.431,0,1,0,1.621,4.723V15.4a.81.81,0,1,0,1.621,0V4.723A2.431,2.431,0,0,0,4.862,2.438Z" transform="translate(7.69 1.951)" fill="#fff"/>
  </g>
        </svg></span>
        <span class="cursor-pointer flex justify-center items-center flex-row-reverse" @click="closeFiltersBox">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43"
                 viewBox="0 0 43 43">
  <defs>
    <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="back" transform="translate(-288 -30)">
    <g transform="matrix(1, 0, 0, 1, 288, 30)" filter="url(#Rectangle_3158)">
      <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)"
            fill="#ff7400"/>
    </g>
    <path id="ic_close_24px"
          d="M17.25,6.234,16.016,5,11.125,9.891,6.234,5,5,6.234l4.891,4.891L5,16.016,6.234,17.25l4.891-4.891,4.891,4.891,1.234-1.234-4.891-4.891Z"
          transform="translate(298 37.541)" fill="#fff"/>
  </g>
</svg>
            <span @click.stop="clearFilters" class="clear-filter">
              حذف تمامی فیلتر ها
            </span>
          </span>
      </div>
      <div class="filters-body">
        <div class="">
          <p>نوع آگهی</p>
          <div class="items-filter">
            <div
                class="checkbox-custom relative inline-block mb-2"
                v-for="(item,i) in addTypes"
                :key="i"
            >
              <input type="checkbox" class="check-box-filter checkbox-custom" name="AdsFilters" v-model="selectedAddTypes" :value="item.name">
              <label  class="check-box-title">{{ item.translation }}</label>
            </div>
          </div>

        </div>
        <div>
          <p>نوع ملک</p>
          <div class="items-filter">
              <div
                  v-if="item < houseTypes.value.length"
                  class="checkbox-custom relative inline-block mb-2"
                  v-for="item in itemShow"
                  :key="item"
              >
                <input type="checkbox" class="check-box-filter checkbox-custom" name="houseTypes" v-model="selectedHouseTypes" :value="houseTypes.value[item - 1]">
                <label  class="check-box-title">{{ houseTypes.value[item - 1] }}</label>
              </div>
          </div>

          <div class="text-white text-center text-sm mt-2" v-if="itemShow < houseTypes.value.length || houseTypes.value.length > itemShow">
            <button @click="itemShow = houseTypes.value.length">نمایش بیشتر</button>
          </div>
        </div>
        <div>
          <p>نوع کاربری</p>
          <div class="items-filter">

            <div
                class="checkbox-custom  relative inline-block mb-2"
                v-for="(item,i) in houseUsages.value"
                :key="i"
            >
              <input type="checkbox" class="check-box-filter checkbox-custom" name="houseUsages" v-model="selectedHouseUsages" :value="item">
              <label  class="check-box-title">{{ item }}</label>
            </div>
          </div>
        </div>
        <div class="mx-3">
          <p>تعداد اتاق خواب</p>
          <vue-slider
              tooltip-placement="bottom"
              :min="0"
              :max="7"
              :interval="1"
              tooltip="none"
              :enable-cross="false"
              v-model="valueNumberBedrooms"
              direction="rtl"
              :dot-options="dotOptions"
          />
          <div class="flex justify-between text-white">
            <span>{{ priceStructure(valueNumberBedrooms[0])}}</span>
            <span>{{priceStructure(valueNumberBedrooms[1])+'+'}}</span>
          </div>
        </div>
        <div class="mx-3">
          <p>قیمت</p>
          <div class="flex justify-between gap-2">
            <input class="input__right-radius"
                   v-model="minPrice"
                   placeholder="حداقل قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="minPrice"
            >
            <input class="input__left-radius"
                   v-model="maxPrice"
                   placeholder="حداکثر قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="maxPrice"
            >
          </div>
        </div>
        <div class="mx-3">
          <p>ودیعه</p>
          <div class="flex justify-between gap-2">
            <input class="input__right-radius"
                   v-model="minDownPayment"
                   placeholder="حداقل قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="minDownPayment"
            >
            <input class="input__left-radius"
                   v-model="maxDownPayment"
                   placeholder="حداکثر قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="maxDownPayment"
            >
          </div>
        </div>
        <div class="mx-3">
          <p>اجاره</p>
          <div class="flex justify-between gap-2">
            <input class="input__right-radius"
                   v-model="minValueRent"
                   placeholder="حداقل قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="minValueRent"
            >
            <input class="input__left-radius"
                   v-model="maxValueRent"
                   placeholder="حداکثر قیمت"
                   v-currency="{ distractionFree: false }"
                   ref="maxValueRent"
            >
          </div>
        </div>
        <div class="mx-3">
          <p>زیر بنا</p>
          <vue-slider
              tooltip-placement="bottom"
              :min="0"
              :max="10000"
              :interval="10"
              tooltip="none"
              :enable-cross="false"
              v-model="valueFoundation"
              direction="rtl"
              :dot-options="dotOptions"
          />
          <div class="flex justify-between text-white">
            <span>{{ priceStructure(valueFoundation[0])}}</span>
            <span>{{priceStructure(valueFoundation[1])}}</span>
          </div>
        </div>
        <div class="mx-3 mb-8">
          <p>سال بنا</p>
          <vue-slider
              tooltip-placement="bottom"
              :min="0"
              :max="100"
              :interval="1"
              tooltip="none"
              :enable-cross="false"
              v-model="valueAgeBuilding"
              direction="rtl"
              :dot-options="dotOptions"
          />
          <div class="flex justify-between text-white">
            <span>{{ priceStructure(valueAgeBuilding[0])}}</span>
            <span>{{priceStructure(valueAgeBuilding[1])}}</span>
          </div>
        </div>
        <div>
          <p>املاک دارای</p>
          <div class="items-filter">
            <div
                v-if="item <= sepcialFeatures.value.length"
                class="checkbox-custom relative inline-block mb-2"
                v-for="item in itemShowSepcialFeatures"
                :key="`A-${item}`"
            >
              <input type="checkbox" class="check-box-filter checkbox-custom" name="sepcialFeatures" v-model="selectedSepcialFeatures" :value="sepcialFeatures.value[item - 1]">
              <label  class="check-box-title">{{ sepcialFeatures.value[item - 1] }}</label>
            </div>
          </div>
          <div class="text-white text-center text-sm mt-2" v-if="itemShowSepcialFeatures < sepcialFeatures.value.length || sepcialFeatures.value.length > itemShowSepcialFeatures">
            <button @click="itemShowSepcialFeatures = sepcialFeatures.value.length">نمایش بیشتر</button>
          </div>
        </div>
        <button @click="handleFilters" class="btn-custom mt-9 w-full">مشاهده نتایج
          <span class="svg-btn-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import axios from "../../plugins/axios";
import {getValue} from "vue-currency-input";
export default {
  name: "filters",
  components :{
    VueSlider
  },
  props :{
    isFiltersOpen :{
      type : Boolean,
      default : false
    },
    homeScroll :{
      type : Element,
    },
    isScrollDown :{
      type : Boolean
    }
  },
  data(){
    return{
      itemShow : 6,
      itemShowSepcialFeatures : 6,
      addTypes : null,
      houseTypes : null,
      houseUsages : null,
      sepcialFeatures : null,
      selectedAddTypes : this.$store.state.filters.filter.advertisement_type.value ,
      selectedHouseTypes : this.$store.state.filters.filter.house_type.value,
      selectedHouseUsages : this.$store.state.filters.filter.usage.value,
      selectedSepcialFeatures : this.$store.state.filters.filter["options->otherFeatures->sepcialFeatures"].value,
      valuePrice : [],
      valueRent: [],
      valuedownPayment: [],
      valueNumberBedrooms: this.$store.state.filters.filter["options->number_of_bedrooms"].value,
      valueFoundation : this.$store.state.filters.filter["options->area"].value,
      valueAgeBuilding : this.$store.state.filters.filter["options->age"].value,
      dotOptions: [{
        style: {
          "backgroundColor": "#fff",
          "border": "1px solid #ff7400",
        },
        focusStyle :{
          "boxShadow": "0px 0px 12px 3px rgba(255, 255, 255, 0.3)"
        }
      }, {
        style: {
          "backgroundColor": "#ff7400",
          "boxShadow" : "unset"
        },
        focusStyle :{
          "boxShadow" : "0 0 1px 4px #a47475"
        },
        tooltip : 'none'
      }],
      baseFilter : {
        advertisement_type: {
          operator: "IN",
          value: []
        },
        "options->otherFeatures->sepcialFeatures": {
          operator: "IN_ARRAY",
          value: []
        },
        house_type: {
          operator: "IN",
          value: []
        },
        usage: {
          operator: "IN_ARRAY",
          value: []
        },
        "options->age": {
          operator: "BETWEEN",
          value: [0, 100]
        },
        "options->area": {
          operator: "BETWEEN",
          value: [0, 10000]
        },
        "options->price": {
          operator: "BETWEEN",
          value: []
        },
        "options->downPayment": {
          operator: "BETWEEN",
          value: []
        },
        "options->rent_price": {
          operator: "BETWEEN",
          value: []
        },
        "options->number_of_bedrooms": {
          operator: "BETWEEN",
          value: [0, 7]
        },

        area: {
          operator: "IN",
          value: []
        }
      },
      minPrice : this.$store.state.filters.filter["options->price"].value[0],
      maxPrice : this.$store.state.filters.filter["options->price"].value[1],
      minDownPayment : this.$store.state.filters.filter["options->downPayment"].value[0],
      maxDownPayment : this.$store.state.filters.filter["options->downPayment"].value[1],
      minValueRent : this.$store.state.filters.filter["options->rent_price"].value[0],
      maxValueRent : this.$store.state.filters.filter["options->rent_price"].value[1]
    }
  },
  methods :{
    priceStructure(v){
      return `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    },
    closeFiltersBox(){
      this.$emit('close-filter-box')
    },
    checkPrice(min = 0 ,max = 0){
      if (min > max){
        this.$toast.error('حداقل قیمت نمیتواند از حداکثر قیمت بیشتر باشد')
        return true;
      }
    },
    async handleFilters(){
      if(! this.checkPrice(this.valuePrice[0],this.valuePrice[1]) && !this.checkPrice(this.valuedownPayment[0],this.valuedownPayment[1]) && !this.checkPrice(this.valueRent[0],this.valueRent[1])){
        const filters = {
          selectedAddTypes : this.selectedAddTypes,
          selectedHouseTypes : this.selectedHouseTypes,
          selectedHouseUsages : this.selectedHouseUsages,
          selectedSepcialFeatures : this.selectedSepcialFeatures,
          valuePrice : this.valuePrice,
          valueRent : this.valueRent,
          valuedownPayment : this.valuedownPayment,
          valueFoundation : this.valueFoundation,
          valueAgeBuilding : this.valueAgeBuilding,
          valueNumberBedrooms : this.valueNumberBedrooms
        }
        await this.$store.commit('filters/CLEAR_ESTATES')
        await this.$store.commit('filters/SET_FILTERS',filters)
        if (JSON.stringify(this.baseFilter) !== JSON.stringify(this.$store.getters["filters/filters"])){
          await this.$store.commit('filters/SET_isFilterEstate' , true)
        }else{
          await this.$store.commit('filters/SET_isFilterEstate' , false)
        }
        await this.$store.dispatch('filters/getDataWithFilter')
        this.$emit('close-filter-box',this.selectedAddTypes);
      }
    },
    clearFilters(){
          this.selectedAddTypes =  []
          this.selectedHouseTypes =  []
          this.selectedHouseUsages =  []
          this.selectedSepcialFeatures =  []
          this.minPrice = null
          this.maxPrice = null
          this.minDownPayment = null
          this.maxDownPayment = null
          this.minValueRent = null
          this.maxValueRent = null
          this.valueNumberBedrooms = [0,7]
          this.valueFoundation =  [0,10000]
          this.valueAgeBuilding =  [0,100]

    }
  },
  mounted() {
    this.$emit('is-scroll',false);
    axios.get('options/ad-types')
        .then(({data})=>{
          this.addTypes = data.data
        })
     axios.get('options/types-and-usages')
        .then(({data})=>{
           this.houseTypes =  data.data.find(item => item.id === 'houseTypes')
           this.houseUsages = data.data.find(item => item.id === 'houseUsages')
        })
    axios.get('options/house')
        .then(({data})=>{
          this.sepcialFeatures =  data.data.find(item => item.id === 'sepcialFeatures')
        })
  },
  watch :{
    minPrice() {
      this.valuePrice[0] = getValue(this.$refs.minPrice);
    },
    maxPrice() {
      this.valuePrice[1] = getValue(this.$refs.maxPrice);
    },
    minDownPayment(){
      this.valuedownPayment[0] = getValue(this.$refs.minDownPayment);
    },
    maxDownPayment(){
      this.valuedownPayment[1] = getValue(this.$refs.maxDownPayment);
    },
    minValueRent(){
      this.valueRent[0] = getValue(this.$refs.minValueRent);
    },
    maxValueRent(){
      this.valueRent[1] = getValue(this.$refs.maxValueRent);
    },

  }

}
</script>

<style>
.vue-slider-dot-tooltip-inner-bottom::after{
  content: unset;
}
.vue-slider-dot-tooltip-inner{
  background: transparent;
  padding-top: 0;
}
.vue-slider-process{
  background-color: #FF7400;
  box-shadow: 0 0 7px 0px #ff7400;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>